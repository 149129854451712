$fa-font-path: "../../../node_modules/font-awesome/fonts/";
@import "../../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "app/assets/scss/base/font-awesome-sass";
@import "app/assets/bootstrap/css/bootstrap.css";
@import "app/assets/scss/base/application-common.scss";
@import "app/assets/scss/base/ng2-toastr.min.scss";



@import "app/assets/scss/base/_variables.scss";


@font-face {
  font-family: "Futura Md BT";
  src: url("app/assets/fonts/tt0142m_.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

body {
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  height: auto !important;
  --my-var1: gray;
  --my-var2: gray;
  --my-var3: gray;
  --mdc-outlined-text-field-focus-outline-color: #003f2d !important;
  --mat-select-focused-arrow-color: #003f2d;
  --mat-datepicker-calendar-date-selected-state-text-color: #fff;
    --mat-datepicker-calendar-date-selected-state-background-color:#003f2d;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: #003f2d;
    --mat-datepicker-calendar-date-today-selected-state-outline-color: #fff;


    --mat-datepicker-toggle-active-state-icon-color:#003f2d;
    --mat-datepicker-calendar-date-in-range-state-background-color: #003f2d;

    --mat-datepicker-toggle-icon-color:#003f2d;

    --mat-datepicker-calendar-date-text-color: #1a1a1a;

    --mat-datepicker-calendar-container-background-color: white;
    --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
    --mdc-outlined-text-field-focus-label-text-color: #003f2d;
    --mdc-filled-text-field-focus-active-indicator-color:#003f2d;
    --mdc-filled-text-field-focus-label-text-color:#003f2d ;
    --mdc-checkbox-selected-focus-state-layer-color: #003f2d;
    --mdc-checkbox-selected-hover-state-layer-color: #003f2d;
    --mdc-checkbox-selected-pressed-state-layer-color: #003f2d;

}

.mdc-button .mdc-button__ripple::before, .mdc-button .mdc-button__ripple::after {
  background-color: #8d8a93;
}

.mdc-tab .mdc-tab__ripple::before, emerald-tab-bar .mdc-tab-bar .mdc-tab-scroller .pagination-button .mdc-tab__ripple::before, .emerald-tab-bar .mdc-tab-bar .mdc-tab-scroller .pagination-button .mdc-tab__ripple::before, .mdc-tab .mdc-tab__ripple::after, emerald-tab-bar .mdc-tab-bar .mdc-tab-scroller .pagination-button .mdc-tab__ripple::after, .emerald-tab-bar .mdc-tab-bar .mdc-tab-scroller .pagination-button .mdc-tab__ripple::after {
  background-color: #8d8a93;
}

emerald-dropdown .mdc-select.mdc-select--filled.mdc-select--opted-custom:hover:not(.mdc-select--disabled) .mdc-line-ripple::before, .emerald-dropdown .mdc-select.mdc-select--filled.mdc-select--opted-custom:hover:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: #003f2d;
}

emerald-dropdown .mdc-select.mdc-select--filled.mdc-select--opted-custom:not(.mdc-select--disabled) .mdc-line-ripple::before {
  border-bottom-color: #003f2d;
}


.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after, .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #fff;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before, .mat-mdc-tab.mdc-tab--active .mat-ripple-element {
  background-color: #8d8a93;
}

.mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before {
  background-color: #8d8a93 !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #1a1a1a;
}

h3 {
  font-size: 20px;
}

label {
  text-transform: capitalize;
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  color: #1a1a1a;

  span {
    font-size: 14px;
    color: red;
  }
}

a:focus,
a:active,
a:visited {
  text-decoration: none;
}

.container-fluid,
.body-content .container-fluid {
  padding: -1px 48px;

  @media print {
    padding: 0;
  }
}

.body-content {
  min-height: 89vh;
  padding-top: 15px;
}

.requiredField {
  color: #ff543e;
}

.modal-content {
  border-radius: 2px;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.w-80 {
  width: 80%;
}

.w-50 {
  width: 50%;
}

.p-relative {
  position: relative;
}

.p-absolute {
  position: absolute;
}

/* Margin and Padding Helper Classes */

/* Margin Top */
.mt-6 {
  margin-top: 6px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-12 {
  margin-top: 12px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-18 {
  margin-top: 18px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-32 {
  margin-top: 32px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-42 {
  margin-top: 42px;
}

.mt-48 {
  margin-top: 48px;
}

.mt-56 {
  margin-top: 56px;
}

.mt-84 {
  margin-top: 84px;
}

/* Margin Bottom */
.mb-6 {
  margin-bottom: 6px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-18 {
  margin-bottom: 18px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-42 {
  margin-bottom: 42px;
}

.mb-48 {
  margin-bottom: 48px;
}

/* Margin Left */
.ml-0 {
  margin-left: 0px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-10 {
  margin-left: 10px;
}

.ml-12 {
  margin-left: 12px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-18 {
  margin-left: 18px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-32 {
  margin-left: 32px;
}

/* Margin Right */
.mr-5 {
  margin-right: 5px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-10 {
  margin-right: 10px !important;
}

.mr-12 {
  margin-right: 12px;
}

.mr-14 {
  margin-right: 14px !important;
}

.mr-16 {
  margin-right: 16px;
}

.mr-18 {
  margin-right: 18px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-32 {
  margin-right: 32px;
}

.mb-16 {
  margin-bottom: 16px;
}

/* Padding Classes */
.p-0 {
  padding: 0;
}

.pl-0 {
  padding-left: 0;
}

.pl-7 {
  padding-left: 7px;
}

.pl-10 {
  padding-left: 10px;
}

.pl-16 {
  padding-left: 16px;
}

.pr-0 {
  padding-right: 0;
}

.pr-7 {
  padding-right: 7px;
}

.new-spinner {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #003f2d;
  /* Blue */
  border-radius: 50%;
  width: 108px !important;
  height: 108px !important;
  animation: spin 2s linear infinite;
  margin-left: -50px;
  margin-top: -50px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.btn-modal-close {
  background: transparent;
  border: none;
  color: #666;
  padding: 0;
}

.title-row {
  margin: 8px 0 24px;
  padding-bottom: 12px;
  border-bottom: 2px solid #195141;

  h5 {
    font-family: "calibre-R", Arial, Helvetica, sans-serif;
    font-weight: 300;
    font-size: 18px;
  }
}

em {
  float: right;
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
  color: #ff543e;
  font-size: 14px;
  padding-left: 10px;
}

.error input {
  background-color: #e3c3c5;
}

.error select {
  background-color: #e3c3c5;
}

.error option {
  background-color: white;
}

.error ::-webkit-input-placeholder {
  color: #999;
}

.error ::-moz-placeholder {
  color: #999;
}

.error :-moz-placeholder {
  color: #999;
}

.panel-heading {
  background-color: gray;
}

.is-valid {
  border-color: green;
}

.is-invalid {
  border-color: #e05c65;
}

.cbre-background {
  color: green;
}

.primary-dark {
  color: $primary700;
}

.red {
  color: $flatRed;
}

.default-table-header-sort {
  font: normal normal normal 14px/1 "FontAwesome", Arial, Helvetica, sans-serif;
  content: "\e156" !important;
  font-family: "Glyphicons Halflings", Arial, Helvetica, sans-serif;
  color: green;
}

.body-content {
  @media print {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.add-grid-section .form-section {
  margin-top: 0;
}

.local-market-wrapper {
  #k-tabstrip-tab-0,
  #k-tabstrip-tab-1 {
    width: 33%;
  }
}

input[type="text"]:disabled#fund,
input[type="text"]:disabled#assetManager,
input[type="text"]:disabled#leasingAgent {
  background-color: #f3f3f4;
  color: #666;
}

.form-control {
  box-shadow: none;
}

.form-control:disabled {
  background: #f3f3f4;
  color: #666;
}

/* Kendo Widget Changes */



.k-pdf-export .print-report-btn-group {
  display: none;
}

.k-pdf-export table {
  border-collapse: collapse;
}

.k-pdf-export .chart-title {
  font-size: 14px;
}

.chart-title {
  font-weight: 300;
  margin-bottom: 14px;

  @media print {
    margin-top: 12px;
  }
}

.financial-year-cont {
  display: none;

  @media print {
    display: block;
  }

  .financial-year-lbl {
    @media print {
      font-size: 9px;
      text-align: right;
    }
  }

  .financial-year {
    @media print {
      font-size: 16px !important;
      text-align: right;
      margin-top: 2px;
      font-weight: 600;
    }
  }
}

.k-pdf-export {
  table.k-grid-table {
    border-collapse: collapse;
  }

  .financial-year-cont {
    float: right;
    display: block;
    position: relative;
    width: 100%;

    .financial-year-lbl {
      font-size: 11px;
      text-align: right;
      position: absolute;
      right: 1px;
    }

    .financial-year {
      font-size: 16px !important;
      font-weight: 600 !important;
      position: absolute;
      right: 0;
      text-align: right;
      margin-top: 12px;
    }
  }
}

.cs-table-header {
  font-size: 10px;
  font-family: "calibre-R", Arial, Helvetica, sans-serif !important;
  //font-family: "calibre-R" !important;
  font-weight: bold;
}

#myProperties {
  .k-grid th {
    white-space: normal !important;
  }
}

.propertyList {
  .k-link {
    white-space: normal;
  }
}

.k-grid th {
  border-width: 0;
  text-overflow: initial;
  // border-bottom: 2px solid $cbre-refreshing;
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
}

.k-grid tr.k-alt {
  background: #fff;
  // border-bottom: 2px solid $cbre-energizing;
  transition: all 200ms ease-out;
}

.k-grid tr.k-alt:hover,
table tr:nth-child(even):hover {
  // background: #E0F4EA;
  transition: all 200ms ease-out;
}

.k-grid td {
  border-width: 0 0 1px 0;
  border-color: #eee;
  padding: 10px 12px;

  &.text-right {
    text-align: right;
  }
}

.k-grid-pager {
  border-color: $cbre-refreshing;
}

k-grid-header .k-header > .k-link {
  text-overflow: initial;
}

.k-grid-header .k-header > .k-link > span::before {
  //font-family: "Glyphicons Halflings";
  font-family: "Glyphicons Halflings", Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: bold;
  color: green;
  content: "\e150" !important;
}

.k-grid-header-wrap {
  border-width: 0;
}

.k-widget {
  border-width: 0;
}

.k-state-selected {
  background: #fff;
  border: 1px solid #ccc;
}

.k-pager-numbers .k-link.k-state-selected,
.k-pager-numbers .k-link:link.k-state-selected {
  color: #333;
}

.k-pager-wrap .k-link.k-state-selected:hover {
  // background-color: transparent;
  // borde-color: transparent;
  color: $cbre-energizing;
}

.k-pager-numbers .k-link,
.k-pager-numbers .k-link:link {
  color: #fff;
}

.k-grid-header .k-header > .k-link {
  text-overflow: inherit;
}

.k-pager-numbers .k-link:hover,
.k-pager-numbers .k-link:link:hover {
  color: $cbre-energizing;
  background: $cbre-primary;
  transition: all 200ms ease-out;
}

.quarter {
  background-color: #e0f4ea !important;
  color: #333;
}

.NOI-Table .k-grid-footer .k-grid-footer-wrap table tfoot .k-footer-template {
  background-color: $cbre-primary;
  color: white;
}

.k-dropzone {
  .k-button {
    color: black !important;
  }
}

.k-tabstrip > .k-content {
  overflow-x: hidden;
}

.k-tabstrip > .k-tabstrip-items .k-item {
  border: 0;
}

.k-tabstrip-top > .k-tabstrip-items .k-item {
  border-radius: 0;
  border-bottom: 2px solid $cbre-primary;
  height: 45px;
  width: 25%;
  text-align: center;
  text-transform: uppercase;
  font-size: 18px;

  span {
    font-family: "calibre-R", Arial, Helvetica, sans-serif;
    line-height: 32px;

    &.k-link {
      color: #333;
    }
  }
}

.k-tabstrip-top > .k-tabstrip-items .k-item.k-state-active {
  background: $cbre-primary;
  border-bottom-color: $cbre-primary;

  span {
    &.k-link {
      color: #fff;
    }
  }
}



.k-pager-wrap {
  background-color: $cbre-refreshing;

  select {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 0;
    padding-left: 8px;
    height: 32px;
    width: 58px !important;
    color: #333;
    box-shadow: none;

    -webkit-border-radius: 0px;
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#00A657' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
  }

  .k-label {
    color: #fff;
  }
}

.k-grid tfoot {
  background: $cbre-primary;
  color: #fff;
}

.k-tabstrip-top > .k-tabstrip-items .k-item + .k-item {
  margin-left: 0;
}

.k-grid-toolbar {
  padding: 12px;
}


.k-button.k-primary,
.k-slider .k-draghandle,
.k-switch-on .k-switch-handle {
  background-color: $cbre-refreshing;
  border-color: $cbre-refreshing;
}

.k-widget ::selection,
.k-block ::selection {
  background-color: $cbre-refreshing;
}

.k-switch-on:hover .k-switch-handle {
  background-color: $cbre-refreshing;
  border-color: $cbre-refreshing;
}

.k-widget.form-control .k-input,
.form-control.k-block .k-input {
  height: auto;
  color: #333;
}

.k-grid-edit-row td > .k-textbox {
  border: none;
  border-bottom: 2px solid #aaa;
  border-radius: 0;
  padding-left: 6px;
  background: transparent;
  color: #333;
  box-shadow: none;
}

.k-grid-edit-row td > .k-textbox:disabled {
  border: none;
  border-bottom: 2px solid #aaa;
  border-radius: 0;
  padding-left: 6px;
  background: #f3f3f4;
  color: #666;
  box-shadow: none;
}

.k-detail-cell {
  padding: 12px 48px 24px 0 !important;
}

.k-combobox .k-dropdown-wrap,
.k-numerictextbox .k-numeric-wrap {
  border-radius: 0;
  border-bottom: 2px solid #aaa;
  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  box-shadow: none;

  &.k-state-disabled {
    border-bottom: 2px solid #aaa;
    background: #f3f3f4;
    color: #666;

    span.k-select {
      background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#CCCCCC' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    }
  }

  span.k-select {
    background: transparent;
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#00A657' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 50% 50%;
    background-repeat: no-repeat;

    span.k-i-arrow-s.k-icon {
      display: flex;
    }
  }
}

.k-grid tr.k-state-selected,
.k-grid td.k-state-selected {
  color: inherit;
  background-color: rgba(27, 159, 194, 0.2);
}

.k-disabled,
.k-state-disabled {
  border-bottom: 2px solid #aaa;
  cursor: not-allowed;
  // opacity: .34;
}

span.k-dropdown-wrap.k-state-disabled {
  opacity: 1;
  cursor: not-allowed;
}

.k-dialog-titlebar {
  border-bottom: none;
  padding: 15px;
  background: #eee;
  text-transform: uppercase;
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
  font-size: 18px;
  color: #333;
}

li.k-button {
  color: #333;
}

.k-pager-wrap .k-link.k-state-disabled {
  border-bottom: none;
  opacity: 0.34;
}

.k-prompt-container,
.k-window-content {
  padding-bottom: 64px;
}

.k-list-container .k-nodata .k-button,
.k-dropdown .k-dropdown-wrap,
.k-button,
.k-toolbar .k-split-button .k-button,
.k-spreadsheet-filter,
.k-pivot-toolbar .k-button,
.k-fieldselector .k-list li.k-item,
.k-colorpicker .k-picker-wrap,
.k-combobox .k-select,
.k-datepicker .k-select,
.k-timepicker .k-select,
.k-datetimepicker .k-select,
.k-dateinput .k-select,
.k-editor .k-editor-toolbar-wrap a.k-tool,
.k-ct-popup .k-editor-toolbar a.k-tool,
.editorToolbarWindow.k-header.k-window-content a.k-tool,
.k-numerictextbox .k-select,
.k-switch-handle,
.k-handle,
.k-gantt-views li,
.k-scheduler-navigation .k-nav-today,
.k-scheduler-navigation .k-nav-prev,
.k-scheduler-navigation .k-nav-next,
.k-scheduler-views li,
.k-scheduler-footer li {
  color: #666;
}

.k-tabstrip > .k-content {
  padding: 0;
}

#investorClientList {
  table tr td input[type="checkbox"]:checked:after {
    color: #fff;
  }
}

#financialYearSelect {
  .k-dropdown .k-dropdown-wrap {
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid #ccc;
    color: #333;
  }

  .k-i-arrow-s .k-icon {
    display: none;
  }

  .k-i-arrow-60-down::before,
  .k-i-arrow-s::before,
  .k-i-sarrow-s::before,
  .k-i-collapse::before,
  .k-i-expand-s::before {
    content: "";
  }

  .k-select {
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#00A657' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}

#assetPerformanceStrip {
  .k-link .k-link-increase,
  .k-link .k-link-decrease {
    display: none;
  }

  .k-dateinput .k-link,
  .k-numerictextbox .k-link {
    display: none;
  }

  // .k-calendar-view .k-link {
  //   width: 45px !important;
  // }
}

#myProperties {
  .k-grid-header .k-header:nth-child(7) > .k-link {
    text-align: right !important;
  }
}

#grossLeasing {
  th.k-header:nth-child(n + 3) {
    text-align: right !important;
  }

  .k-grid-edit-row td:nth-child(n + 3) > .k-textbox {
    text-align: right;
    background: transparent;
    padding-right: 0;
    color: #000;
  }

  .k-grid-edit-row td > .k-textbox {
    border-color: $cbre-refreshing;
  }

  .k-grid-edit-row td > .k-textbox:disabled {
    text-align: right;
    background: transparent;
    border: none;
    padding-right: 0;
    color: #000;
  }

  textarea.k-textbox {
    height: 42px;
    margin-bottom: 6px;
  }

  tr.k-footer-template > td:nth-child(2) {
    text-transform: uppercase;
  }

  tr.k-footer-template > td:nth-child(n + 3) {
    text-align: right !important;
  }
}

#subMarket {
  th.k-header:nth-child(n + 3) {
    text-align: right !important;
  }

  .k-grid-edit-row td:nth-child(n + 3) > .k-textbox {
    text-align: right;
    background: transparent;
    padding-right: 0;
    color: #000;
  }

  .k-grid-edit-row td > .k-textbox {
    border-color: $cbre-refreshing;
  }

  .k-grid-edit-row td > .k-textbox:disabled {
    text-align: right;
    background: transparent;
    border: none;
    padding-right: 0;
    color: #000;
  }

  textarea.k-textbox {
    height: 42px;
    margin-bottom: 6px;
  }
}

#subMarketQuarter {
  th.k-header:nth-child(n + 3) {
    text-align: right !important;
  }

  .k-grid-edit-row td:nth-child(n + 3) > .k-textbox {
    text-align: right;
    background: transparent;
    padding-right: 0;
    color: #000;
  }

  .k-grid-edit-row td > .k-textbox {
    border-color: $cbre-refreshing;
  }

  .k-grid-edit-row td > .k-textbox:disabled {
    // text-align: right;
    background: transparent;
    border: none;
    padding-right: 0;
    color: #000;
  }

  .k-combobox .k-dropdown-wrap.k-state-disabled,
  .k-numerictextbox .k-numeric-wrap.k-state-disabled {
    background: transparent;
    color: #333;
    text-align: right;
    opacity: 1;
    border: none;

    .k-select {
      display: none;
    }
  }

  .k-numeric-wrap > .k-input {
    text-align: right;
  }

  textarea.k-textbox {
    height: 42px;
    margin-bottom: 6px;
  }
}

#NOI {
  th.k-header:nth-child(n + 3):nth-child(-n + 6) {
    text-align: right !important;
  }

  .k-grid-edit-row td:nth-child(n + 3):nth-child(-n + 6) > .k-textbox {
    text-align: right;
    background: transparent;
    padding-right: 0;
    color: #000;
  }

  tr.k-footer-template > td:nth-child(2) {
    text-transform: uppercase;
  }

  tr.k-footer-template > td:nth-child(n + 3) {
    text-align: right !important;
  }

  .k-grid-edit-row td > .k-textbox {
    border-color: $cbre-refreshing;
  }

  .k-grid-edit-row td > .k-textbox:disabled {
    text-align: right;
    background: transparent;
    border: none;
    padding-right: 0;
    color: #000;
  }

  textarea.k-textbox {
    height: 42px;
    margin-bottom: 6px;
  }
}

#revenue {
  th.k-header:nth-child(n + 3):nth-child(-n + 6) {
    text-align: right !important;
  }

  .k-grid-edit-row td:nth-child(n + 3):nth-child(-n + 6) > .k-textbox {
    text-align: right;
    background: transparent;
    padding-right: 0;
    color: #000;
  }

  tr.k-footer-template > td:nth-child(2) {
    text-transform: uppercase;
  }

  tr.k-footer-template > td:nth-child(n + 3) {
    text-align: right !important;
  }

  .k-grid-edit-row td > .k-textbox {
    border-color: $cbre-refreshing;
  }

  .k-grid-edit-row td > .k-textbox:disabled {
    text-align: right;
    background: transparent;
    border: none;
    padding-right: 0;
    color: #000;
  }

  textarea.k-textbox {
    height: 42px;
    margin-bottom: 6px;
  }
}

#expense {
  th.k-header:nth-child(n + 3):nth-child(-n + 6) {
    text-align: right !important;
  }

  .k-grid-edit-row td:nth-child(n + 3):nth-child(-n + 6) > .k-textbox {
    text-align: right;
    background: transparent;
    padding-right: 0;
    color: #000;
  }

  tr.k-footer-template > td:nth-child(2) {
    text-transform: uppercase;
  }

  tr.k-footer-template > td:nth-child(n + 3) {
    text-align: right !important;
  }

  .k-grid-edit-row td > .k-textbox {
    border-color: $cbre-refreshing;
  }

  .k-grid-edit-row td > .k-textbox:disabled {
    text-align: right;
    background: transparent;
    border: none;
    padding-right: 0;
    color: #000;
  }

  textarea.k-textbox {
    height: 42px;
    margin-bottom: 6px;
  }
}

#outstanding {
  th.k-header:nth-child(n + 3):nth-child(-n + 7) {
    text-align: right !important;
  }

  .k-grid-edit-row td:nth-child(n + 3):nth-child(-n + 7) > .k-textbox {
    text-align: right;
    background: transparent;
    padding-right: 0;
    color: #000;
  }

  .k-grid-edit-row td > .k-textbox {
    border-color: $cbre-refreshing;
  }

  .k-grid-edit-row td > .k-textbox:disabled {
    text-align: right;
    background: transparent;
    border: none;
    padding-right: 0;
    color: #000;
  }

  textarea.k-textbox {
    height: 42px;
    margin-bottom: 6px;
  }
}

#asset-group-properties-tbl {
  th.k-header {
    background: #fff;
  }

  th.k-header:nth-child(n + 9) {
    text-align: right !important;
  }

  .k-grid-edit-row td:nth-child(n + 9) {
    text-align: right !important;
  }
}










#propertyDetail {
  span.k-dropdown-wrap.k-state-default {
    border-color: $cbre-refreshing;
  }

  .k-numerictextbox {
    background: #f3f3f4;
  }

  span.k-dropdown-wrap.k-state-disabled {
    border-color: #ccc;
    background: #f3f3f4;
    cursor: not-allowed;
  }

  input[type="text"]:enabled,
  input[type="search"]:enabled {
    &.form-control {
      border-color: $cbre-refreshing;
    }
  }

  input[type="text"]:disabled,
  input[type="search"]:disabled {
    &.form-control {
      border-color: #ccc;
      background: #f3f3f4;
      color: #666;
    }
  }
}

.kdf-print-export #singlePropReport,
#singlePropReport {
  page-break-after: avoid;
  page-break-before: avoid;

  #prop-report-bdgt .k-grid-content,
  #prop-report-leasing .k-grid-content,
  #prop-report-total-outstanding .k-grid-content,
  #prop-report-ar-comments .k-grid-content,
  #propReportFinancials .k-grid-content,
  #client-satisfaction .k-grid-content {
    overflow: hidden;
  }

  label {
    letter-spacing: 0;
  }

  table {
    border-collapse: collapse;
  }

  th,
  td,
  th.k-header {
    font-size: 10px;
    padding: 3px 6px;
    text-overflow: initial;
    white-space: nowrap;

    @media print {
      font-size: 6pt;
    }
  }

  td.variance-notes-q {
    border-right: 1px solid #ddd;
  }

  td,
  .k-grid td {
    font-size: 10px;
    padding: 3px 6px;
    vertical-align: middle;
    text-overflow: initial;
    white-space: nowrap;

    @media print {
      font-size: 6pt;
    }
  }

  table tr th.k-header {
    height: 20px !important;
  }

  #propReportFinancials,
  #prop-report-leasing,
  #prop-report-bdgt,
  #prop-report-total-outstanding {
    th.k-header:nth-child(n + 2) {
      text-align: right;
    }

    .k-grid:nth-child(n + 2) td {
      text-align: right;
    }

    th.k-header,
    td.k-grid {
      font-size: 10px;
      padding: 3px 6px;
      text-overflow: initial;
      white-space: nowrap;

      @media print {
        font-size: 6pt;
      }
    }
  }
}

#singlePropReport {
  .table.notes-table {
    td {
      white-space: normal;
    }
  }
}



#propReportFinancials {
  .k-grid td:first-child {
    text-transform: uppercase;
  }

  .k-grid td:nth-child(n + 2) {
    text-align: right;
  }

  tr:nth-child(3n + 3) {
    border: 2px solid $cbre-refreshing;
  }
}

#prop-report-leasing {
  .k-grid td:first-child {
    text-transform: uppercase;
  }

  .k-grid td:nth-child(n + 2) {
    text-align: right;
  }

  tr:nth-child(5) {
    border: 2px solid $cbre-refreshing;
  }
}

.text-wrap {
  white-space: initial !important;
}

.text-center {
  text-align: center;
}

.k-pdf-export #propReportFinancials {
  .k-grid td:first-child {
    text-transform: uppercase;
  }

  .k-grid td:nth-child(n + 2) {
    text-align: right;
  }

  tr:nth-child(3n + 3) {
    border: 2px solid $cbre-refreshing;
  }
}

.k-pdf-export #prop-report-leasing {
  .k-grid td:first-child {
    text-transform: uppercase;
  }

  .k-grid td:nth-child(n + 2) {
    text-align: right;
  }

  tr:nth-child(1n + 7) {
    border: 2px solid $cbre-refreshing;
  }
}

#prop-report-bdgt {
  .k-grid td:first-child {
    text-transform: uppercase;
  }

  .k-grid td:nth-child(n + 2) {
    text-align: right;
  }
}

#prop-report-total-outstanding {
  .k-grid td:first-child {
    text-transform: uppercase;
  }

  .k-grid td {
    text-align: right;
  }
}

.text-caps {
  font-weight: 600;
}

.groupedProps {
  th {
    font-family: "calibre-R", Arial, Helvetica, sans-serif;
    font-weight: 600;
    background-color: #fff;
    color: #333;
  }

  .k-grid-table {
    tr {
      background: #fff;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
    }

    td:nth-child(3) {
      text-align: center;
    }
  }
}

#client-satisfaction {
  .k-grid td:first-child {
    text-transform: uppercase;
  }

  th.k-header:nth-child(n + 2) {
    text-align: right;
  }

  .chartNum,
  .ratingWorst,
  .ratingBad,
  .ratingMid,
  .ratingGood,
  .ratingGreat {
    padding: 0 !important;
    border: 1px solid #f3f3f4;
  }

  .ratingTitle {
    text-transform: uppercase;

    @media print {
      font-size: 9px;
    }
  }
}

.numeric-header {
  text-align: right !important;
}

.whole-cell {
  display: block;
  font-size: 11px !important;
  text-align: right;
  font-weight: bold;
  color: #333;
  padding: 6px 8px;

  @media print {
    font-size: 10px !important;
    font-weight: initial;
    // background: inherit !important;
  }
}

/* End Kendo Changes */

input[type="checkbox"]:after {
  content: "";
  display: inline-block;
  position: relative;
  top: -3px;
  left: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
}

input[type="checkbox"]:checked:after {
  content: "\EA10";
  padding: 2px 0 0 2px;
  font-family: "Calibre-R", Arial, Helvetica, sans-serif !important;
  color: #fff;
  background: #003f2d;
}

input[type="text"],
input[type="search"] {
  &.form-control {
    border: none;
    border-bottom: 2px solid #aaa;
    border-radius: 0;
    padding-left: 6px;
    background: transparent;
    color: #767676;
    box-shadow: none;
  }
}

input[type="search"] {
  &.form-control {
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' height='24' width='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='#CCCCCC'><path d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
  }
}

input[type="text"]:disabled,
input[type="search"]:disabled {
  &.form-control {
    border-bottom: 2px solid #aaa;
    background: #f3f3f4;
    color: #666;
  }
}

input[type="text"]:focus {
  border-color: #003f2d;
  transition: all 200ms ease-out;
}

input[type="text"] {
  &#search.form-control {
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' height='24' width='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' fill='#CCCCCC'><path d='M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
  }
}

select {
  box-shadow: none;
}

select {
  &.form-control {
    border-radius: 0;
    border: none;
    border-bottom: 2px solid #aaa;
    padding-left: 6px;
    background: #fff;
    color: #767676;
    box-shadow: none;

    -webkit-border-radius: 0px;
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#00A657' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
  }
}

select:focus {
  &.form-control {
    border-bottom: 2px solid $cbre-refreshing;
    box-shadow: none;
  }
}

table {
  border-collapse: collapse;

  @media print {
    border-collapse: collapse !important;
  }
}

table th {
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
  background: #ffffff;
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 600;
}

td {
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
  font-size: 14px;
  color: #767676;

}

td.edit-row > i {
  color: #777;
  transition: all 200ms ease-out;
}

td.edit-row > i:hover {
  color: $cbre-refreshing;
  transition: all 200ms ease-out;
}

th.k-header a {
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
}

table tr:nth-child(even) {
  background: #f5f5f6;
  transition: all 200ms ease-out;
}

.table-search,
.table-select {
  margin-bottom: 18px;
  display: inline-block;
  position: relative;
  width: 50%;
}

.tbl-filter {
  margin: 12px 14px 18px 0;
  width: 320px;
  display: inline-block;
}

/* Modal Styling */
.modal-header {
  background: #eee;

  h4.modal-title {
    font-family: "calibre-R", Arial, Helvetica, sans-serif;
    font-size: 16px;
  }
}

/* End Modal Styling */

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.vab-btn {
  vertical-align: bottom;
}

.table-add-btn {
  background: transparent;
  //color: #666;
  color: #767676;
  border: none;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.table-add-btn:hover {
  background: transparent;
  color: #1a1a1a;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 200ms linear;
}

.table-edit-btn {
  background: transparent;
  //color: #666;
  color: #003f2d;
  border: none;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.table-edit-btn:hover {
  background: transparent;
  color: #003f2d;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 200ms linear;
}

.table-save-btn {
  background: transparent;
  //color: #666;
  color: #767676;
  border: none;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.table-save-btn:hover {
  background: transparent;
  color: #1a1a1a;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 200ms linear;
}

.table-save-no-hover-btn {
  background: transparent;
  color: #1a1a1a;
  border: none;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.table-cancel-btn {
  background: transparent;
  //color: #666;
  color: #767676;
  border: none;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.table-cancel-btn:hover {
  background: transparent;
  color: #1a1a1a;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 200ms linear;
}

.table-delete-btn {
  background: transparent;
  //color: #666;
  color: #ff543e;
  border: none;
  -webkit-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -ms-transition: all 200ms linear;
  -o-transition: all 200ms linear;
  transition: all 200ms linear;
}

.table-delete-btn:hover {
  background: transparent;
  color: #ff543e;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 200ms linear;
}

.modal-btn-grp-top {
  margin-top: -8px;
}

.loader {
  border-top: 16px solid $cbre-primary;
  border-right: 16px solid $cbre-refreshing;
  border-bottom: 16px solid $cbre-enterprising;
  border-left: 16px solid #ccc;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.text-primary {
  color: $cbre-refreshing;
}

.dt-actions-row * {
  display: inline-block;
}

/* Modal Panel Styling */

.panel.panel-default {
  border-radius: 0;
}

.panel-head {
  padding: 10px 16px;
  background-color: #ebebeb;
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  color: #333;
}

.panel-body {
  padding: 16px 16px 30px;
  background-color: #fff;
}

/* Data Table Overrides */

table.dataTable tbody > tr.selected,
table.dataTable tbody > tr > .selected {
  background: #d4e3d0;
  color: #333;
}

.dt-buttons.btn-group {
  margin-top: 12px;

  .btn {
    padding: 4px 10px;
    font-size: 12px;

    span {
      font-weight: 600;
      font-family: "calibre-R", Arial, Helvetica, sans-serif;
    }
  }
}

.body-content .dataTables_wrapper .dataTables_filter label {
  padding-left: 0;
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
  font-size: 14px;
}

.body-content .dataTables_wrapper .dataTables_filter label input {
  margin-left: -50px;
  width: 320px;
}

div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0;
}

#propertyListTable_wrapper.dataTables_wrapper {
  .dt-buttons.btn-group {
    float: right !important;
    margin-top: -56px !important;
  }
}

#clientListTable_filter,
#userListTable_filter {
  margin-bottom: 8px;
  width: 50%;
  float: left;

  label {
    float: left;
    font-family: "calibre-R", Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 10px;
  }

  input {
    font-family: "calibre-R", Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 14px;
  }
}

#userListTable_wrapper {
  margin-top: 32px;

  .dt-buttons.btn-group {
    float: right;
  }
}

.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
  margin-right: 12px;
}

.dataTables_info {
  padding: 12px;
  height: 48px;
  width: 50%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  float: right;
  text-align: right;
  line-height: 32px;
  background: $cbre-primary;
  color: #fff;
}

.dataTables_paginate.paging_simple_numbers {
  float: left;
  padding: 12px;
  height: 48px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 50%;
  background: $cbre-primary;
  color: #fff;

  ul.pagination {
    float: left;

    li > a {
      margin-top: -6px;
      padding: 0 12px;
      height: 32px;
      line-height: 33px;
      color: #fff;
      background: transparent;
      border: none;
    }

    .active > a {
      border: 1px solid #ccc;
      background: #fff;
      color: #333;
    }
  }
}

.toast-top-full-width {
  width: 25%;
  top: 16px;
  right: 16px;
}

.modal-btn-group {
  position: relative;
  display: block;
  margin: 0 auto;
  text-align: center;
}

/* Check box styling */
.btn-config {
  color: $cbre-primary;
}

.btn-config,
.table-add-btn,
.table-edit-btn,
.table-delete-btn,
.table-save-btn,
.table-cancel-btn,
.table-add-btn:focus,
.table-edit-btn:focus,
.table-delete-btn:focus,
.table-save-btn:focus,
.table-cancel-btn:focus,
.table-add-btn:active,
.table-edit-btn:active,
.table-delete-btn:active,
.table-save-btn:active,
.table-cancel-btn:active,
.table-add-btn:visited,
.table-edit-btn:visited,
.table-delete-btn:visited,
.table-save-btn:visited,
.table-cancel-btn:visited {
  border: none;
  background: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.table-save-btn {
  color: $cbre-refreshing;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  h3 {
    font-size: 18px;
  }



  .title-row {
    margin: 8px 0 24px;
  }

  .container-fluid,
  .body-content .container-fluid {
    padding: 0 20px;
  }

  .tbl-filter {
    width: 236px;
  }

  th.k-header,
  .k-grid td,
  td {
    width: 148px;
  }

  .pl-0 {
    padding-left: 0;
  }

  th,
  td {
    min-width: 124px;
  }

  .modal-dialog {
    width: 680px;
  }

  .k-tabstrip-top > .k-tabstrip-items .k-item span.k-link {
    font-size: 11px;
  }

  .new-prop-btn {
    margin-top: -9px;
  }

  #propertyListTable_wrapper.dataTables_wrapper .dt-buttons.btn-group {
    float: left !important;
    margin-top: 0 !important;
    margin-bottom: 16px;
  }

  .dt-buttons.btn-group {
    display: block;
    float: left !important;
  }

  .body-content {
    min-height: 85vh;
  }

  .dataTables_paginate.paging_simple_numbers {
    width: 64%;
  }

  div.dataTables_wrapper div.dataTables_info {
    width: 36%;
  }

  header.header .container-fluid .header-content {
    display: none;
  }

  .local-market-wrapper {
    #k-tabstrip-tab-0,
    #k-tabstrip-tab-1 {
      width: 50%;

      span.k-link {
        font-size: 12px;
      }
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) {
  .navbar .navbar-nav li ul.dropdown-menu li a {
    margin: 7px 0;
  }

  h3 {
    font-size: 16px;
  }

  h4 {
    font-size: 14px;
  }

  h4.modal-title {
    font-size: 14px;
  }

  label {
    font-size: 9px;
    // margin-top: 16px;
  }

  body {
    overflow-x: hidden;
  }

  .container-fluid,
  .body-content .container-fluid {
    padding: 0 16px;
  }

  .tbl-filter {
    width: 200px;
  }

  th.k-header,
  .k-grid td,
  td {
    width: 128px;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  th,
  td {
    min-width: 124px;
  }

  .navbar.active {
    padding-left: 24px;
  }

  .title-row {
    margin: 8px 0 24px;
  }

  .dt-buttons.btn-group {
    display: none;
  }

  header.header .container-fluid .header-content {
    display: none;
  }

  .modal-dialog {
    width: 92%;
    margin: 24px auto;
  }

  .k-tabstrip-top > .k-tabstrip-items .k-item span.k-link {
    font-size: 11px;
  }

  .local-market-wrapper {
    #k-tabstrip-tab-0,
    #k-tabstrip-tab-1 {
      width: 50%;

      span.k-link {
        font-size: 12px;
      }
    }
  }
}

.k-window-title {
  font-family: "calibre-R", Arial, Helvetica, sans-serif;
  font-weight: bold;
  font-size: 16px;
}







@media screen and (min-width: 414px) and (max-width: 540px) {
  .body-content {
    min-height: 75vh;
  }
}

@media screen and (min-width: 375px) and (max-width: 413px) {
  .body-content {
    min-height: 72vh;
  }
}

@media screen and (min-width: 320px) and (max-width: 374px) {
  .body-content {
    min-height: 69vh;
  }
}

@media screen and (min-width: 320px) and (max-width: 420px) {
  #propertyModal {
    label {
      margin-top: 12px;
    }
  }

  .btn {
    font-size: 12px;
    line-height: 1.5;
  }

  .fa-2x {
    font-size: 1.5em;
  }

  .tbl-filter {
    width: 100%;
  }

  .tbl-filter:nth-child(2) {
    width: 84%;
    margin-top: 0;
  }

  ul.k-pager-numbers.k-reset {
    display: none;
  }

  .k-pager-wrap .k-pager-info {
    display: none;
  }

  .k-dialog {
    width: 96%;
  }

  .k-tabstrip-top > .k-tabstrip-items .k-item span.k-link {
    font-size: 9px;
  }

  .backToAll {
    font-size: 12px;
  }

  .prop-dropdown {
    font-size: 12px;
  }


  .dataTables_paginate.paging_simple_numbers {
    width: 100%;
  }

  div.dataTables_wrapper div.dataTables_info {
    display: none;
  }

  div.dataTables_wrapper div.dataTables_paginate {
    width: 100%;
  }

  .dataTables_paginate.paging_simple_numbers ul.pagination li > a {
    font-size: 12px;
  }

  #clientListTable_filter,
  #userListTable_filter {
    width: 100%;
  }

  #clientListTable_filter label,
  #userListTable_filter label {
    width: 100%;
  }

  .body-content .dataTables_wrapper .dataTables_filter label input {
    width: 100%;
    margin-left: -100%;
  }

  .local-market-wrapper {
    #k-tabstrip-tab-0,
    #k-tabstrip-tab-1 {
      width: 50%;

      span.k-link {
        font-size: 12px;
      }
    }
  }
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.search label {
  margin-right: 20px;
  float: left;
  font-weight: 700;
  font-size: 14px;
  line-height: 2;
}

.search .search-field {
  margin-right: 20px;
  float: left;
  width: calc(100% - 140px);
  margin-bottom: 15px;
  line-height: 1.8;
}

.search .search-button {
  width: 100px;
  float: left;
}

.fa-1p6 {
  font-size: 1.6em !important;
}

.fa-3p0 {
  font-size: 3em !important;
}

.apbpositionClass {
  top: 50%;
  left: 40%;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #003f2d !important;
}

.mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: #003f2d !important;
}

tr.mat-row.ng-star-inserted.unread td {
  color: #00b2dd;
  cursor: pointer;
}

tr.mat-row.ng-star-inserted.duedateexpiry td {
  color: #ec008c !important;
  cursor: pointer;
}

.mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #003f2d;
}

.configuration .mat-card-header-text {
  display: none;
}

.config_table mat-header-cell > div,
.config_table mat-header-cell > div > button {
  width: 100%;
}

.config_table mat-header-cell button div {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.config_table mat-cell > .editable {
  width: 100%;
  padding-right: 10px;
}

mat-header-cell button,
mat-header-cell,
th.mat-header-cell,
th.mat-header-cell button {
  text-transform: uppercase !important;
}



.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #003f2d !important;
}

tree-select tree-select-item .item .item div {
  margin-left: 50px !important;
}

tree-select .selected-item-item {
  font-size: 10px !important;
}

tree-select .close {
  font-size: 10px !important;
  line-height: 0.85 !important;
  margin-left: 5px !important;
  padding-top: 7px !important;
}

.cdk-overlay-pane {
  z-index: 99999;
}



.mat-datepicker-content {
  min-height: 380px !important;
}



.block {
  display: block;
}

.flex {
  display: flex !important;

  &.vertical-align-m {
    vertical-align: middle;
  }

  &.align-c {
    align-items: center;
  }

  &.align-b {
    align-items: baseline;
  }

  &.align-e {
    align-items: end;
  }

  &.end {
    justify-content: end;
  }

  &.start {
    justify-content: left;
  }

  &.sb {
    justify-content: space-between;
  }

  &.row {
    flex-direction: row;
  }

  &.column {
    flex-direction: column;
  }

  .grow-1 {
    flex-grow: 1;
  }

  .grow-2 {
    flex-grow: 2;
  }

  .grow-3 {
    flex-grow: 3;
  }

  .grow-4 {
    flex-grow: 4;
  }
}

.center {
  display: flex;
  vertical-align: middle;
  //align-items: center;
  justify-content: center;
}

// table starts
tr.mat-row,
tr.mat-header-row {
  border-bottom: 1px solid $grey500;
}

tr.mat-row:hover {
  background: whitesmoke;
}

// table ends

// mat-option
.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: $primary700;
}

.k-list__item.k-state-selected,
.k-list .k-state-selected.k-item,
.k-state-selected.k-list-optionlabel {
  color: white;
  background-color: #003f2d !important;
}

.k-multiselect .k-multiselect-wrap,
.k-dropdowntree .k-multiselect-wrap:hover {
  border-color: #003f2d !important;
}

.mat-checkbox-checked .mat-checkbox-background {
  background-color: #003f2d !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #003f2d !important;
  /*inner circle color change*/
}

::ng-deep .transparent-dialog .mat-dialog-container {
  background-color: transparent !important;
  box-shadow: none !important;
}

::ng-deep{
 .transparent-dialog .cdk-overlay-backdrop {
  background-color: transparent !important;
}

}

/* Inside your Angular component's stylesheet */

// ::ng-deep .mat-drawer-backdrop.mat-drawer-shown,
// .main-toolbar {
//   visibility: visible;
//   background-color: transparent;
// }
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: transparent;
}

.mat-mdc-radio-button.mat-accent {
  --mdc-radio-selected-hover-icon-color: #003f2d !important;
  --mdc-radio-selected-icon-color: #003f2d !important;
}
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  --mat-option-selected-state-label-text-color: #003f2d !important;
}

.material-icons {
  color: #003f2d;
}

// .mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background,
// .mdc-checkbox__ripple {
//   background-color: #003f2d !important;
//   border-color: #003f2d !important;
// }

// .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
// .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
//   background: #003f2d !important;
// }
.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  --mat-option-selected-state-label-text-color: #003f2d !important;
}

.mat-mdc-header-cell{
  font-size: 14px !important;

}

.mdc-data-table__cell{
  font-size: 12px !important;
}

.parent-table {
  .tooltip {
    opacity: 1 !important;
    transform: none !important;
    inset: inherit !important;
    top: -10px !important;
    right: 8px !important;
  }
}

.custom-tooltip .tooltip  {
  opacity: 1 !important;
  transform: none !important;
  inset: inherit !important;
}